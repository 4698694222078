
import { defineComponent, watch, ref, PropType, reactive, computed } from 'vue';
import { Empty, message } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { createApiUrl, timeFormat, dealEmpty } from '@/utils/utils';
import { request } from '@/utils/request';


export default defineComponent({
  name: 'Message',
  props: {
    messageList: {
      type: Array as PropType<any[]>,
      default: () => ([]),
    },
  },
  setup(_props, _ctx) {
    const route = useRoute();
    const store = useStore();

    const loading = ref(false);
    const filterParams = reactive<{ readStatus: string | number }>({ readStatus: '' });
    const pagination = reactive({ page: 1, pageSize: 10 });
    const totalRow = ref(0);
    const activeNames = ref(Number(route.query.id) || '');
    const messageAction = ref(1);
    const readStatusSum = computed(() => store.state.User!.unreadNum);
    const list = ref<Array<any>>([]);

    const queryList = async () => {
      loading.value = true;
      const params: any = { ...filterParams, ...pagination };
      if (messageAction.value === 1 && route.query.id) params.currentMessageId = route.query.id;
      try {
        const { data } = await request.post(createApiUrl('/newlinkSass/message/queryMessageDetailPage'), { ...dealEmpty(params) }, { noEnterpriseId: true });
        loading.value = false;
        list.value = data.list || [];
        totalRow.value = data.total || 0;
      } catch (e:any) {
        loading.value = false;
        message.error(e.message);
      }
    };

    // 更新消息读取状态 1 悬浮窗消息点击 2 消息列表点击 3 全部已读
    const updateMessageDetailBatch = async (params: any, type: number) => {
      try {
        await request.post(createApiUrl('/newlinkSass/message/updateMessageDetailBatch'), { ...params });
        await store.dispatch('User/setUnreadNum');
        if (type === 3) message.success('所有的消息都已设为已读状态');
        if (type === 2) {
          list.value = list.value.map(item => (Number(activeNames.value) === item.id ? { ...item, readStatus: 1 } : item));
          return;
        } 
        if (!readStatusSum.value) {
          messageAction.value = 1;
          filterParams.readStatus = '';
        }
        queryList();
      } catch (e:any) {
        message.error(e.message);
      }
    };

    const handleTabs = (value: number) => {
      switch (value) {
        case 1:
          pagination.page = 1;
          totalRow.value = 0;
          filterParams.readStatus = '';
          messageAction.value = value;
          queryList();
          return;
        case 2:
          if (!readStatusSum.value) return message.error('暂无未读消息');
          messageAction.value = value;
          pagination.page = 1;
          totalRow.value = 0;
          filterParams.readStatus = 0;
          queryList();
          return;
        case 3:
          if (!readStatusSum.value) return message.error('暂无未读消息');
          filterParams.readStatus = '';
          updateMessageDetailBatch({ allFlag: true }, 3);
          return;
        default:
          return '';
      }
    };

    const handleChange = (value: number) => {
      activeNames.value = Number(value);
      const c = list.value.find(item => item.id === activeNames.value);
      if (activeNames.value && !c.readStatus) updateMessageDetailBatch({ messageId: activeNames.value }, 2);
    };

    const sizeChange = (_current: number, size: number) => {
      pagination.pageSize = size;
      queryList();
    };
    const currentChange = (page: number, _pageSizee: number) => {
      pagination.page = page;
      queryList();
    };

    const init = () => {
      if (route.query.id) {
        activeNames.value = Number(route.query.id);
        updateMessageDetailBatch({ messageId: route.query.id }, 1);
      } else {
        queryList();
      }
    };

    init();

    watch(() => route.query, (val: any) => {
      pagination.page = 1;
      pagination.pageSize = 10;
      totalRow.value = 0;
      messageAction.value = 1;
      filterParams.readStatus = '';
      if (val.id) {
        activeNames.value = Number(val.id);
        updateMessageDetailBatch({ messageId: route.query.id }, 1);
      } else {
        activeNames.value = '';
        queryList();
      }
    }, { deep: true });

    return {
      readStatusSum,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      timeFormat,
      activeNames,
      messageAction,
      list,
      pagination,
      totalRow,
      loading,
      handleTabs,
      handleChange,
      sizeChange,
      currentChange,
    };
  },
});
