
import { defineComponent, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'Bus',
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    onMounted(() => {
      if (!route.query || !route.query.to) {
        return router.replace('/');
      }
      let to: object;

      try {
        to = JSON.parse(route.query.to as string);
      } catch (e: Error & any) {
        return router.replace('/');
      }

      router.replace(to);
    });
    return {};
  },
});
